import { CountryCodes } from 'src/common/enums';
import {
  DefaultLocation,
  NewSearchLocation,
} from 'src/global/models/NewSearchLocation';

type SpecialRegionItem = { id: string; name: string; cities: string[] };
type SpecialRegionsType = Record<CountryCodes.ID, Array<SpecialRegionItem>>;

const SpecialRegions: SpecialRegionsType = {
  [CountryCodes.ID]: [
    {
      id: 'JABODETABEK',
      name: 'Jabodetabek',
      /* city areas inside jabodetabek */
      cities: [
        'ea61f4ac-5864-4b2b-a2c8-aa744a2aafea', // Jakarta Utara
        '3d6ac5e8-f12d-40cd-823d-44597402ea3b', // Jakarta Timur
        '078b37b2-e791-4739-958e-c29192e5df3e', // Jakarta Selatan
        'e2a615bb-0997-42c8-a018-c4a1768ae01f', // Jakarta Pusat
        'af0ed74f-1b51-43cf-a14c-459996e39105', // Jakarta Barat
        'ddd28ed9-d36c-48e1-aa74-de3006122569', // Kab. Kepulauan Seribu
        '262b7d3c-2c51-4854-a7e8-6635e0657338', // Bogor
        'fbc373c5-cadc-4dd8-acb3-1e57850e2a08', // Kab. Bogor
        'f5817918-7ce1-436d-9478-c438b3466adb', // Depok
        '87c39cfb-0e3c-4edb-a7a0-754fb70cc587', // Bekasi
        '826664b3-1f31-497f-bc8a-a23699b1a531', // Kab. Bekasi
        '337f8da8-70d8-4916-9733-1cee8e4902e9', // Tangerang Selatan
        'ae3c458e-5947-4833-8f1b-e001ce2fad1d', // Tangerang
        'd305a80e-4891-45a4-8c5b-29c9242f431e', // Kab. Tangerang
      ],
    },
  ],
};

export const specialRegionsForCountry = (
  code: CountryCodes.ID
): SpecialRegionItem[] => SpecialRegions[code];

export const getIsSpecialLocation = (locationId: string): boolean => {
  for (const region of SpecialRegions[CountryCodes.ID]) {
    if (region.id === locationId) {
      return true;
    }
  }
  return false;
};

export const getSpecialRegionCities = (id: string) => {
  return Object.keys(SpecialRegions)
    .map((key: CountryCodes.ID) =>
      SpecialRegions[key]
        .filter((item) => item.id === id)
        .map((item: SpecialRegionItem) => item.cities)
    )
    .flat(2);
};

export const contains = (mainString: string, subString: string) => {
  return mainString
    .toLowerCase()
    .includes(subString.trim().toLocaleLowerCase());
};

export const getSpecialRegions = (
  countryCode: CountryCodes
): NewSearchLocation[] => {
  const code = countryCode as keyof SpecialRegionsType;
  const regions = SpecialRegions[code] ?? [];
  return regions.map(
    (region: SpecialRegionItem) =>
      new NewSearchLocation(
        null,
        null,
        new DefaultLocation(region.id, countryCode, region.name)
      )
  );
};

export const getFilterSpecialRegions = (
  specialRegions: NewSearchLocation[],
  searchTerm: string
) => {
  return specialRegions.filter((region: NewSearchLocation) =>
    contains(region.label, searchTerm)
  );
};
